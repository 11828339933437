import Layout from "../containers/Layout";

import PageHelmet from "../components/PageHelmet";
import * as styles from "./404.module.scss";
import { bodyTextStyle } from "../components/typography.module.scss";
import { cn } from "../lib/helpers";
import QSiteSettings, { QSiteSettingsProps } from "../queries/QSiteSettings";
import Socials from "../components/Socials";

const ComingSoonPage = () => (
  <Layout>
    <PageHelmet title="Coming Soon" />

    <QSiteSettings>
      {({ site }: QSiteSettingsProps) => {
        const { facebookUrl, twitterUrl, instagramUrl, twitchUrl, discordUrl } =
          site;
        return (
          <div className={cn(bodyTextStyle, styles.root)}>
            <div>
              <h1>Coming Soon</h1>
              <p>This link isn't ready yet. Follow us for updates!</p>
              <Socials
                isInline
                facebookUrl={facebookUrl}
                twitterUrl={twitterUrl}
                instagramUrl={instagramUrl}
                twitchUrl={twitchUrl}
                discordUrl={discordUrl}
              />
            </div>
          </div>
        );
      }}
    </QSiteSettings>
  </Layout>
);

export default ComingSoonPage;
